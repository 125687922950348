<!-- 股权结构 -->
<template>
  <div class="shareholding-structure">
    <div class="topBtn">
      <IconButton content="同步天眼查" icon="iconfont iconshuaxin" @click.prevent="syncData" v-if="type!=='details'"></IconButton>
      <div class="addBtn" @click="add" v-if="type!=='details'">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="tableColumns"
      :dataSource="tableData"
      :tableAttrs="{
        stripe: true
      }"
      :is-caculate-height="false"
      ref="tableRef"
      style="margin-top: 10px"
      :staticData="true"
    >
      <!-- <template #shareholdersNameHeader><font style="color: #ee1c1c">*</font>股东名称</template> -->
      <template #code="scope">{{scope.$index + 1}}</template>
      <!-- 表单 -->
      <template slot="shareholdersName" slot-scope="scope">
        <sz-input v-model="scope.row.shareholdersName" placeholder="请输入" v-if="type!=='details' && scope.row.dataSources !== 'TIANYANCHA'"></sz-input>
        <div v-else>{{scope.row.shareholdersName}}</div>
      </template>
      <template slot="shareholdersType" slot-scope="scope">
        <BaseSelect v-model="scope.row.shareholdersType" placeholder="请选择" :options="typeList"
          v-if="type!=='details' && scope.row.dataSources !== 'TIANYANCHA'" />
        <div v-else>{{scope.row.shareholdersType === 1 ? '企业':'个人'}}</div>
      </template>
      <template #shareholdingRatio="{row}">
        <amount-input v-model="row.shareholdingRatio" placeholder="请输入" type="BL" v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"></amount-input>
        <div v-else>{{row.shareholdingRatio}}</div>
      </template>
      <template #subscribedAmount="{row}">
        <amount-input v-model="row.subscribedAmount" placeholder="请输入" type="10Y" v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"></amount-input>
        <div v-else>{{(row.subscribedAmount ? row.subscribedAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')}}</div>
      </template>
      <template #subscribedTime="{row}">
        <el-date-picker
          v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"
          v-model="row.subscribedTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        />
        <div v-else>{{row.subscribedTime?formatDate(row.subscribedTime, 'YY-MM-DD'):''}}</div>
      </template>
      <template #participationTime="{row}">
        <el-date-picker
          v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"
          v-model="row.participationTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        />
        <div v-else>{{row.participationTime}}</div>
      </template>
      <template #paidAmount="{row}">
        <amount-input v-model="row.paidAmount" placeholder="请输入" type="10Y" v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"></amount-input>
        <div v-else>{{row.paidAmount}}</div>
      </template>
      <template #paidTime="{row}">
        <el-date-picker
          v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"
          v-model="row.paidTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        />
        <div v-else>{{row.paidTime?formatDate(row.paidTime, 'YY-MM-DD'):''}}</div>
      </template>
      <template #holdingEntity="{row}">
        <sz-input v-model="row.holdingEntity" placeholder="请输入" v-if="type!=='details' && row.dataSources !== 'TIANYANCHA'"></sz-input>
        <div v-else>{{row.holdingEntity}}</div>
      </template>
      <template #remark="{row}">
        <sz-input v-model="row.remark" placeholder="请输入" v-if="type !== 'details'"></sz-input>
        <div v-else>{{row.remark}}</div>
      </template>
      <template #operation="scope">
        <icon-button
          v-if="type !== 'details' && scope.row.dataSources !== 'TIANYANCHA'"
          @click.prevent="del(scope.$index)"
          content="删除"
          icon="iconfont iconshanchu1"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { getColumns } from '../utils/shareholding-structure.config'
import SzInput from '@/components/input/sz-input/sz-input.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { supplierApi } from '@/api/companyApi'
import { formatDate } from '@/utils/auth/common'
export default {
  components: { BaseTable, SzInput, AmountInput, IconButton, BaseSelect },
  props: {
    type: String,
    shareholdingListData: Array,
    enterpriseName: String
  },
  data () {
    return {
      formatDate: formatDate,
      typeList: [
        { id: 2, name: '个人' },
        { id: 1, name: '企业' }
      ],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < new Date('1990-01-01') || time.getTime() > new Date('2100-01-01')
        }
      }
    }
  },
  computed: {
    tableColumns () {
      return getColumns(this)
    },
    tableData: {
      get () {
        return this.shareholdingListData
      },
      set (val) {
        this.$emit('update:shareholdingListData', val)
      }
    }
  },
  methods: {
    // 添加
    add () {
      this.tableData.push({ dataSources: 'CUSTOMER' })
    },
    // 删除
    del (index) {
      this.tableData.splice(index, 1)
    },
    // 同步
    syncData () {
      if (!this.enterpriseName) {
        this.warning('企业名称不能为空')
        return
      }
      supplierApi.synchronousTycGqjg({
        enterpriseName: this.enterpriseName
      }).then(res => {
        if (res.data) {
          // 天眼查新数据同步时删除
          const customerData = this.tableData.filter(s => s.dataSources !== 'TIANYANCHA' || s.enterpriseId)
          const datas = (res.data || []).concat(customerData)
          this.tableData.splice(0, Infinity, ...datas)
          this.success('同步完成')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shareholding-structure{
  .topBtn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    .addBtn{
      background: #2862E7;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      margin-left: 5px;
    }
  }
  /deep/ .el-table{
    .cell{
      >div,>.el-input {
        width: calc(100% - 1px) !important;
      }
    }
  }
}
</style>
