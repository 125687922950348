export const getColumns = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: 50
    },
    {
      label: '股东名称',
      prop: 'shareholdersName',
      width: 150
    },
    {
      label: '股东类型',
      prop: 'shareholdersType',
      width: 130
    },
    {
      label: '持股比例(%)',
      prop: 'shareholdingRatio',
      width: 130
    },
    {
      label: '认缴出资额(元)',
      prop: 'subscribedAmount',
      width: 160
    },
    {
      label: '认缴出资日期',
      prop: 'subscribedTime',
      width: 180
    },
    // {
    //   label: '参股日期',
    //   prop: 'participationTime',
    //   width: 160
    // },
    // {
    //   label: '实缴出资额(元)',
    //   prop: 'paidAmount',
    //   width: 140
    // },
    // {
    //   label: '实缴出资日期',
    //   prop: 'paidTime',
    //   width: 160
    // },
    // {
    //   label: '金地控股主体',
    //   prop: 'holdingEntity',
    //   width: 180
    // },
    {
      label: '数据来源',
      prop: 'dataSources',
      width: 120,
      formatter: row => row.dataSources === 'TIANYANCHA' ? '天眼查同步' : '用户添加'
    },
    {
      label: '备注',
      prop: 'remark'
    },
    {
      label: '操作',
      prop: 'operation',
      width: 60,
      isHidden: content.type === 'details'
    }
  ]
}
